// Default maximum width for the content area, used as the maximum width for images spanning entire width of content area.
export const MAX_CONTENT_WIDTH = 1248

const MOBILE_BREAKPOINT = 480
const TABLET_BREAKPOINT = 768
const DESKTOP_BREAKPOINT = 1280

const MEDIA_BREAKPOINTS = [MOBILE_BREAKPOINT, TABLET_BREAKPOINT, DESKTOP_BREAKPOINT]

type ImageSource = {
  srcset: string
  media: string
}

type GetImageSourcesOptions = {
  maxWidth?: number
}

/**
 * Generates responsive image sources for optimal performance.
 *
 * @param url - The base URL of the image.
 * @param options - Optional configuration object
 * @returns An array of objects containing `srcset` and `media` properties.
 */
export const getImageSources = (url: string, options: GetImageSourcesOptions = {}): ImageSource[] => {
  const {maxWidth} = options

  const createSrcset = (width?: number) => {
    if (width) {
      return `${url}?w=${width}&fm=webp 1x, ${url}?w=${width * 2}&fm=webp 2x`
    }
    return `${url}?fm=webp`
  }

  const sources = MEDIA_BREAKPOINTS.map(size => {
    // Ensure the size does not exceed the maxWidth (if provided)
    const sizeValue = maxWidth && size > maxWidth ? maxWidth : size
    return {
      srcset: createSrcset(sizeValue),
      media: `(max-width: ${size}px)`,
    }
  })

  const desktopSource = {
    srcset: createSrcset(maxWidth),
    media: `(min-width: ${DESKTOP_BREAKPOINT}px)`,
  }

  return [...sources, desktopSource]
}
